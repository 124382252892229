/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  .item-menu {
    display: flex;
    margin: auto !important;
    width: fit-content;
    text-align: center;
    @include media-breakpoint-down(md) {
      display: block;
    }
    li {
      display: inline-block;
      margin: 0 30px;
      @include media-breakpoint-down(md) {
        margin: 0;
        width: 100%;
      }
      .item-link {
        color: #0e0e0e;
        font-family: "Public Sans";
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        &:hover {
          color: #c22c47;
        }
      }
      &.logo-ft {
        @include media-breakpoint-down(md) {
          display: none;
        }
        a {
          margin-top: -24px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    .item-menu {
      margin-right: 25px;
    }
  }
  .cms {
    color: #0e0e0e;
    font-family: "Public Sans";
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
  }
}