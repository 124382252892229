/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: #4a4a4a;
  font-family: "Public Sans";
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: left;
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

h2 {
  color: #c22c47;
  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  @include media-breakpoint-down(sm) {
    text-align: center;
    font-size: 24px;
  }
}

h3 {
  color: #c22c47;
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  @include media-breakpoint-down(xl) {
    font-size: 20px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 18px;
  }
}

button, input, textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.clearfix {
  clear: both;
  width: 100%;
}

.btn {
  border-radius: 31px;
  background-color: #c22c47;
  border: 1px solid #c22c47;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  display: table !important;
  margin: auto;
  padding-left: 25px;
  padding-right: 55px;
  transition: all ease 0.3s;
  @include media-breakpoint-down(lg) {
    padding-right: 25px;
  }
  &:after {
    content: '';
    background-image: url('../img/icon/arr.svg');
    width: 18px;
    height: 11px;
    display: inline-block;
    margin-left: 10px;
    transition: all ease 0.3s;
    position: absolute;
    top: 20px;
    @include media-breakpoint-down(lg) {
      content: none;
    }
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #c22c47;
    color: #c22c47;
    transition: all ease 0.3s;
    &:after {
      content: '';
      background-image: url('../img/icon/arrow-red.svg');
      width: 18px;
      height: 11px;
      display: inline-block;
      margin-left: 15px;
      transition: all ease 0.3s;
      @include media-breakpoint-down(lg) {
        content: none;
      }
    }
  }
}

.txtc {
  text-align: center;
}

.mauto {
  margin: auto;
  display: block;
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: initial;
}

a, img, span, button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.flex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.is-mobile {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.is-desktop {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.scrollToTop {
  width: 49px;
  height: 49px;
  background-color: #ffb900;
  border-radius: 100%;
  position: fixed;
  font-size: 24px;
  color: #fff;
  border: none;
  right: 30px;
  bottom: 60px;
  display: none;
  cursor: pointer;
  @include media-breakpoint-down(md) {
    display: none;
  }
  img {
    margin-bottom: 6px;
    width: 45%;
  }
}