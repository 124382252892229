/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background: white;
  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: all ease 0.5s;
    .item-logo {
      img {
        height: 60px;
        transition: all ease 0.5s;
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;
        margin-top: 10px;
        li {
          &:not(:last-child) {
            margin-right: 50px;
          }

          .item-link {
            color: #0e0e0e;
            font-family: "Public Sans";
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            &:hover {
              color: #c22c47;
            }
          }
          &.acheter {
            a {
              color: #ffffff;
              font-family: "Public Sans";
              letter-spacing: normal;
              line-height: normal;
              text-align: left;
              text-transform: uppercase;
              border-radius: 25px;
              position: relative;
              background-color: #c22c47;
              border: 1px solid #c22c47;
              padding: 15px 40px;
              margin-top: -12px;
              font-weight: 600;
              transition: all ease 0.3s;
              &:hover {
                background-color: #fff;
                color: #c22c47;
                transition: all ease 0.3s;
              }
            }
          }
          &.rs {
            position: relative;
            .fb {
              width: 49px;
              height: 49px;
              background-color: #ffb900;
              border: 1px solid #ffb900;
              border-radius: 50%;
              position: absolute;
              top: -12px;
              transition: all ease 0.3s;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 22px;
                height: auto;

                path {
                  transition: fill ease 0.3s;
                }
              }

              &:hover {
                background-color: #fff;
                transition: all ease 0.3s;

                svg {

                  path {
                    fill: #ffb900;
                  }
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          margin-right: 25px;
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      margin-top: 0px;
    }
    @include media-breakpoint-down(sm) {
      margin-right: 25px;
    }

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $very-dark-grey;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  &.active {
    .header-container {
      padding-top: 5px;
      padding-bottom: 5px;
      transition: all ease 0.5s;
      .item-logo {
        img {
          height: 50px;
          transition: all ease 0.5s;
          @include media-breakpoint-down(lg) {
            height: 40px;
          }
        }
      }
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          text-transform: uppercase;
          color: $very-dark-grey;
          font-size: 30px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          @include media-breakpoint-down(xs) {
            font-size: 26px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 60px;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }

}

#page-404 .banner {
  background-color: #E8880A;
  text-align: center;
  h1 {
    color: #fff;
    font-size: 42px;
    font-family: "graphik", sans-serif;
    z-index: 1;
    position: relative;
  }
  .back-home{
    color: #fff;
    font-size:18px;
    text-decoration: underline;
    z-index: 1;
    position: relative;
  }
}
.kid{
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 440px;
  z-index: 0;
  @include media-breakpoint-down(sm) {
    width: 280px;
  }
}