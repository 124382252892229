/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  @extend .bg-cover;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: $black;

  .item-content {
    width: 100%;
    padding: 100px 0;
    @include media-breakpoint-down(sm) {
      padding: 30px 0 110px 0;
    }
  }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {

  .custom-card-link {
    display: block;
  }

  /*
  * Card img
  */
  .card-img-container {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .card-img {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: transform 2s $easeOutExpo;
      will-change: transform;
    }
  }

  /*
  * Card content
  */
  .card-content {

    .date {
      color: rgba($very-dark-grey, 0.6);
    }
  }

  /*
  * Hover state
  */
  &:hover {

    .card-img-container {

      .card-img {
        transform: scale(1.05);
      }
    }
  }

}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 30px;
  }

  h1 {
    @extend .title-xxl;
  }
  h2 {
    @extend .title-xl;
  }
  h3 {
    @extend .title-lg;
  }
  h4 {
    @extend .title-md;
  }
  h5 {
    @extend .title-sm;
  }
  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url('../img/icon/quote-left.svg');

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url('../img/icon/quote-right.svg');

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: $headerHeight;

  @include media-breakpoint-down(lg) {
    padding-top: $headerHeightSm;
  }
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.error404 {
  .banner {
    min-height: calc(100vh - 50px);
  }
}

.page-template-cms {
  .banner {
    min-height: 400px;
    background-color: #E8880A;
    .item-content{
      margin-top: 130px!important;
      color: #ffffff;
    }
  }
  h2,h3{
    padding-top: 20px;
  }
}

.contact-form{
  a{
    color:#fff;
    text-decoration: underline;
  }
}