/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

// @font-face {
//     font-family: 'helvetica-neue';
//     src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot');
//     src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff2') format('woff2'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff') format('woff'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.ttf') format('truetype'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.svg#helveticaneuelight') format('svg');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'helvetica-neue';
//     src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot');
//     src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff2') format('woff2'),
//         url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff') format('woff'),
//         url('../fonts/helvetica-neue/helveticaneue_medium-webfont.ttf') format('truetype'),
//         url('../fonts/helvetica-neue/helveticaneue_medium-webfont.svg#helveticaneuemedium') format('svg');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'helvetica-neue';
//     src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot');
//     src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff2') format('woff2'),
//         url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff') format('woff'),
//         url('../fonts/helvetica-neue/helveticaneuebd-webfont.ttf') format('truetype'),
//         url('../fonts/helvetica-neue/helveticaneuebd-webfont.svg#helveticaneuebold') format('svg');
//     font-weight: 700;
//     font-style: normal;
// }


@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Light.eot');
    src: url('../fonts/graphik/Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Light.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Light.woff') format('woff'),
        url('../fonts/graphik/Graphik-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Regular.eot');
    src: url('../fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Regular.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Regular.woff') format('woff'),
        url('../fonts/graphik/Graphik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Medium.eot');
    src: url('../fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Medium.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Medium.woff') format('woff'),
        url('../fonts/graphik/Graphik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Bold.eot');
    src: url('../fonts/graphik/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Bold.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Bold.woff') format('woff'),
        url('../fonts/graphik/Graphik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'canela';
    src:
        url('../fonts/canela/Canela-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/canela/Canela-Regular.otf') format('opentype'),
        url('../fonts/canela/Canela-Regular.woff') format('woff'),
        url('../fonts/canela/Canela-Regular.ttf') format('truetype'),
        url('../fonts/canela/Canela-Regular.svg#Canela-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'canela';
    src:
        url('../fonts/canela/Canela-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/canela/Canela-Thin.otf') format('opentype'),
        url('../fonts/canela/Canela-Thin.woff') format('woff'),
        url('../fonts/canela/Canela-Thin.ttf') format('truetype'),
        url('../fonts/canela/Canela-Thin.svg#Canela-Thin') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'canela';
    src:
        url('../fonts/canela/Canela-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/canela/Canela-Light.otf') format('opentype'),
        url('../fonts/canela/Canela-Light.woff') format('woff'),
        url('../fonts/canela/Canela-Light.ttf') format('truetype'),
        url('../fonts/canela/Canela-Light.svg#Canela-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}