/*
|--------------------
|     PAGE HOME
|--------------------
*/

#page-home {
  .banner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 900px;
    padding-top: 125px;
    background-color: #000000;
    @include media-breakpoint-down(sm) {
      min-height: initial;
    }
    &:after {
      content: "";
      background-image: url('../img/global/filtre.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      bottom: 0;
      left: 0;
      right: 0;
      height: 236px;
      width: 100%;
      position: absolute;
      z-index: 1;
      transition: all ease 0.3s;
    }
    h1 {
      color: #ffffff;
      text-align: center;
      font-size: 42px;
      @include media-breakpoint-down(sm) {
        font-size: 27px;
      }
    }
    .cms {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
      text-align: center;
    }
    .btn-decouvrir {
      color: #ffffff;
      font-family: "Rubik";
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      cursor: pointer;
      &:after {
        content: '';
        background-image: url('../img/icon/arr-down.svg');
        background-repeat: no-repeat;
        padding-top: 15px;
        width: 9px;
        height: 16px;
        display: inline-table;
        margin-left: 10px;
        transition: all ease 0.3s;
      }
      &:hover {
        &:after {
          padding-top: 13px;
          transition: all ease 0.3s;
        }
      }
    }
  }
  #methodes,
  #methodes2 {
    .block-img {
      position: relative;
      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }
      img {
        width: 417px;
        position: absolute;
        max-width: initial;
        top: -100px;
        left: 60px;
        border-radius: 20px;
        @include media-breakpoint-down(md) {
          width: 260px;
          position: absolute;
          max-width: initial;
          top: -40px;
          left: 50px;
          right: 0;
          margin: auto;
        }
        @include media-breakpoint-down(sm) {
          top: 0px;
          left: 0px;
          right: 0;
        }
      }
      .shadow {
        width: 407px;
        height: 497px;
        border-radius: 12px;
        background-color: #c22c47;
        @include media-breakpoint-down(lg) {
          width: 407px;
          top: -40px;
        }
        @include media-breakpoint-down(md) {
          width: 257px;
          height: 317px;
          margin: 0 auto;
        }
      }
    }
  }
  #methodes2 {
    img {
      right: 60px !important;
      left: initial !important;
      @include media-breakpoint-down(md) {
        left: 50px !important;
        right: 0 !important;
      }
    }
    .shadow {
      float: right !important;
      @include media-breakpoint-down(md) {
        float: initial !important;
      }
    }
  }
  #ouvrages {
    .ouvrage {
      height: 480px;
      padding: 30px;
      border-radius: 12px;
      border: 2px solid #c22c47;
      position: relative;
      @include media-breakpoint-down(xl) {
        height: 550px;
      }
      @include media-breakpoint-down(lg) {
        height: 480px;
      }
      @include media-breakpoint-down(md) {
        height: initial;
      }
      img {
        height: 180px;
        margin: auto;
        display: block;
        max-width: 100%;
      }
      .link {
        color: #c22c47;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        float: left;
        @include media-breakpoint-down(md) {
          position: relative;
          bottom: initial;
          padding-top: 30px;
          margin: auto;
          display: block;
          float: initial;
        }
        &:after {
          content: '';
          background-image: url('../img/icon/arrow-red.svg');
          width: 18px;
          height: 11px;
          display: inline-block;
          margin-left: 10px;
          transition: all ease 0.3s;
        }
        &:hover {
          &:after {
            content: '';
            margin-left: 20px;
            transition: all ease 0.3s;
          }
        }
      }
    }

    .c-rouge {
      .ouvrage {
        border: 2px solid #c22c47;
        h3 {
          color: #c22c47;
        }
        .link {
          color: #c22c47;
          &:after {
            content: '';
            background-image: url('../img/icon/arrow-red.svg');
          }
        }
      }
    }
    .c-jaune {
      .ouvrage {
        border: 2px solid #e8880a;
        h3 {
          color: #e8880a;
        }
        .link {
          color: #e8880a;
          &:after {
            content: '';
            background-image: url('../img/icon/arrow-yellow.svg');
          }
        }
      }
    }
    .c-violet {
      .ouvrage {
        border: 2px solid #6f5d97;
        h3 {
          color: #6f5d97;
        }
        .link {
          color: #6f5d97;
          &:after {
            content: '';
            background-image: url('../img/icon/arrow-purple.svg');
          }
        }
      }
    }

  }
  #enseignants {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    .btn {
      margin: inherit;
      @include media-breakpoint-down(sm) {
        margin: auto;
      }
    }
    .block-img {
      position: relative;
      img {
        width: 457px;
        position: absolute;
        max-width: initial;
        float: right;
        top: -100px;
        right: 60px;
        @include media-breakpoint-down(lg) {
          width: 407px;
          top: -40px;
        }
        @include media-breakpoint-down(md) {
          width: 260px;
          position: absolute;
          max-width: initial;
          top: -40px;
          left: 50px;
          right: 0;
          margin: auto;
        }
        @include media-breakpoint-down(sm) {
          top: 0px;
          left: 0px;
          right: 0;
        }
      }
      .shadow {
        width: 407px;
        height: 537px;
        float: right;
        border-radius: 12px;
        background-color: #ffb900;
        @include media-breakpoint-down(md) {
          width: 257px;
          height: 317px;
          margin: 0 auto;
          float: initial;
        }
      }
    }
  }
  #auteurs {
    background-color: #f5f5f5;
    .onglets {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      .onglet {
        cursor: pointer;
        opacity: 0.7;
        transition: all ease 0.2s;
        .title-auteur {
          color: #c22c47;
          font-family: "Montserrat";
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;
          transition: all ease 0.2s;
        }
        .img-auteur {
          height: 121px;
          width: 121px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          margin: 10px auto;
          border-radius: 50%;
        }
        &.active,
        &:hover {
          opacity: 1;
          transition: all ease 0.2s;
          .title-auteur {
            color: #e8880a;
            transition: all ease 0.2s;
          }
        }
      }
    }
    .description {
      background-color: #fff;
      padding: 25px;
      height: 400px;
      position: relative;
      display: none;
      opacity: 0;
      border-radius: 15px;
      transition: all ease 0.8s;
      @include media-breakpoint-down(sm) {
        display: block;
        opacity: 1;
        height: initial;
      }
      &.active {
        display: block;
        opacity: 1;
        transition: all ease 0.8s;
      }
      .img {
        margin-right: 30px;
        max-width: 350px;
        height: 350px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin: auto;
        display: block;
        @include media-breakpoint-down(sm) {
          max-width: 100%;
          height: 100px;
        }
      }
      .desc {
        height: 350px;
        @include media-breakpoint-down(sm) {
          height: initial;
          margin-top: 15px;
        }
      }
    }
  }
  #contact {
    background-color: #6f5d97;
    h2 {
      color: #fff;
      text-align: center;
    }
    .cms {
      color: #ffffff;
    }
    .contact-form {
      .gform_fields {
        display: flex;
        .gfield {
          margin: 9px 0;
          padding: 0px 15px;
          max-width: 100% !important;
        }
      }
      input,
      textarea {
        border-radius: 12px;
        background-color: #fff;
        border: none;
        padding: 33px 20px;
        border-radius: 12px;
        width: 100%;
      }

      .ginput_container_select {
        background-color: #fff;
        border: none;
        overflow: hidden;
        position: relative;
        border-radius: 12px;
        width: 100%;
        margin: 7px 0 0 0;
        &:after {
          content: '';
          background-image: url('../img/icon/arrow-p.svg');
          background-repeat: no-repeat;
          width: 24px;
          height: 17px;
          position: absolute;
          top: 20px;
          right: 26px;
          display: block;
          border-color: transparent;
          border-width: 6px;
          border-style: solid;
          pointer-events: none;
        }
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: #fff;
          width: 100%;
          height: auto;
          border: 0;
          margin: 0;
          padding: 20px 20px;
          border-radius: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ginput_container_consent {
        height: 15px;
        width: 15px;
        border-radius: initial;
        background: none;
        padding: 0;
        margin-right: 5px;
      }

      .field_1_4 {
        max-width: 100% !important;
        textarea {
          padding: 20px 20px !important;
        }
      }

      #field_1_6 { /* champ rgpd */
        .ginput_container_consent {
          height: 15px;
          border-radius: initial;
          background: none;
          padding: 0;
          margin-right: 5px;
          margin-top: 0;
          width: 100%;
          label {
            top: 4px;
            color: #fff;
            line-height: 19px;
            font-size: 14px;
            position: relative;
            float: left;
            padding: 0 10px 10px 3px;
          }
          input {
            background: none;
            border: none;
            padding: 0;
            border-radius: 0;
            width: initial;
            appearance: checkbox;
            margin: 0;
            float: left;
            position: absolute;
          }
        }
      }

      .gform_footer {
        @include media-breakpoint-down(sm) {
          padding: 45px 0 10px 0;
          text-align: center;
        }
        #gform_submit_button_1 {
          color: #6f5d97;
          font-family: "Rubik";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;
          width: 200px;
          padding: 20px 0;
          border-radius: 50px;
          position: relative;
        }
      }
      .gfield_description {
        padding-top: 0;
      }
      .gfield_required {
        display: none;
      }
      .gfield_error {
        background: none !important;
        border: none !important;
      }
      .validation_error {
        color: #ffb900 !important;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 25px;
        border: 1px solid #ffb900 !important;
        padding: 16px;
        clear: both;
        width: 100%;
        text-align: center;
      }
      .validation_message {
        color: #fab903;
        font-weight: 500;
        letter-spacing: normal;
        font-size: 14px;
      }
      .gform_confirmation_message {
        color: #fff;
        text-align: center;
        font-size: 20px;
        border: none;
        padding: 10px;
      }
    }

    #field_1_6 {
      &.gfield_error {
        border: 1px solid #fab903 !important;
        label {
          color: #fab903 !important;
          a {
            color: #fab903 !important;
          }
        }
        #validation_message_1_6 {
          display: none;
        }
      }
    }

  }
}
