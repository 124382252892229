/*
|--------------------
|      NEWS
|--------------------
*/

.load-more-container {

    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}

